<template>
  <div class="w-100">
    <div class="title-actions">
      <h5 class="title">{{$t('tournaments')}}</h5>
      <div class="btns">
        <b-form-input type="search" v-model="filter" :placeholder="$t('search')" autofocus @keyup.esc="clearFilter" />
        <b-button v-if="user.type === 'SUPER'" variant="info" :to="{ name: 'TournamentCategoriesList', params: { lang: lang, console: console } }">{{ $tc('category', 2) | toCapitalize }}</b-button>
        <b-button variant="info" :to="{ name: 'TournamentCreate', params: { lang: lang, console: console } }">
          <i class="fa fa-plus"></i>
        </b-button>
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <b-table striped bordered hover :items="tournaments" :fields="columns" :filter="filterForUsers">
      <div slot="name" slot-scope="row">
        <router-link :to="{ name: 'TournamentBackDivisions', params: { lang: lang, console: console, tournamentId: row.item.id } }">{{ row.item.name }}</router-link>
      </div>
      <div slot="quantity" slot-scope="tournament" class="text-right">{{ tournament.value }}</div>
      <template slot="actions" slot-scope="tournament">
        <div class="w-100 text-center">
          <b-dropdown id="ddTournamentOptions" right noCaret>
            <div slot="button-content" class="px-2">
              <i class="fa fa-ellipsis-v"></i>
            </div>
            <b-dropdown-item @click="openModalChangeImage(tournament.item)">{{ $t('changeImage') }}</b-dropdown-item>
            <b-dropdown-item @click="openModalAddTeam(tournament.item)">{{ $t('addTeam') }}</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'TournamentEdit', params: { lang: lang, console: console, tournamentId: tournament.item.id } }">{{ $t('edit') }}</b-dropdown-item>
            <b-dropdown-item @click.native="showBanners(tournament.item)">Banners</b-dropdown-item>
            <b-dropdown-item @click="showPlayers(tournament.item)">{{ $t('players') }}</b-dropdown-item>
            <b-dropdown-item @click="showTeams(tournament.item)">{{ $t('teams') }}</b-dropdown-item>

            <b-dropdown-item v-if="tournament.item.lock_signing" @click="showSignings(tournament.item)">{{ $t('transfersClosed') }}</b-dropdown-item>
            <b-dropdown-item v-else @click="showSignings(tournament.item)">{{ $t('transfersOpened') }}</b-dropdown-item>

            <b-dropdown-item @click="showDivisions(tournament.item)">{{ $t('divisions') }}</b-dropdown-item>
            <b-dropdown-item @click="showRules(tournament.item)">{{ $t('rules') }}</b-dropdown-item>
            <b-dropdown-item @click="showCustomButtons(tournament.item)">{{ $t('customButtons') }}</b-dropdown-item>
            <b-dropdown-item @click="showNotices(tournament.item)">{{ $t('notices') }}</b-dropdown-item>
            <b-dropdown-item @click="showAdministrators(tournament.item)">{{ $tc('administrator', 2) | toCapitalize }}</b-dropdown-item>
            <b-dropdown-item v-if="user.type === 'SUPER'" @click="money(tournament.item)">{{ $t('handOutMoney') }}</b-dropdown-item>
            <b-dropdown-item v-if="user.type === 'SUPER'" :to="{ name: 'TournamentBackAwards', params: { lang: lang, console: console, tournamentId: tournament.item.id } }">{{ $tc('award', 2) | toCapitalize }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <b-pagination align="center" :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
    <modal-change-image v-if="tournament" :open-close.sync="hideShowModalChangeImage" :tournament="tournament"></modal-change-image>
    <modal-add-teams v-if="tournament" :open-close.sync="hideShowModalAddTeam" :tournament="tournament"></modal-add-teams>
    <modal-add-money v-if="tournament" :tournament="tournament"></modal-add-money>
    <modal-signings />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
const debounce = require('lodash.debounce')
import ModalChangeImage from './ChangeImage'
import ModalAddTeams from './AddTeam'
import ModalAddMoney from './ModalAddMoney'
import ModalSignings from './ModalSignings'

export default {
  components: {
    ModalChangeImage,
    ModalAddTeams,
    ModalAddMoney,
    ModalSignings
  },
  data () {
    return {
      filterForUsers: '',
      totalRows: 1,
      perPage: 25,
      currentPage: 1,
      filter: '',
      columns: [
        { key: 'name', label: this.$t('name'), sortable: true },
        { key: 'quantity', label: 'Premium', sortable: true },
        { key: 'actions', label: '' }
      ],
      debounceFn: null,
      hideShowModalChangeImage: false,
      hideShowModalAddTeam: false,
      hideShowModalMoney: false
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'tournaments',
      'tournament',
      'user',
      'console'
    ])
  },
  mounted() {
    this.changeTitleBar(this.$t('tournaments'))
    if (this.tournaments.length < 1) {
      this.fetchData()
    }
  },
  watch: {
    filter () {
      this.debounceFn()
    },
    currentPage () {
      this.updateData()
    }
  },
  created () {
    this.debounceFn = debounce(() => {
      this.updateData()
    }, 1000)
  },
  methods: {
    showDivisions (tournament) {
      this.$store.dispatch('SET_TOURNAMENT', tournament)
      this.$router.push({ name: 'TournamentBackDivisions', params: { lang: this.lang, console: this.console, tournamentId: tournament.id } })
    },
    showAdministrators (tournament) {
      this.$store.dispatch('SET_TOURNAMENT', tournament)
      this.$router.push({ name: 'TournamentBackAdministrators', params: { lang: this.lang, console: this.console, tournamentId: tournament.id } })
    },
    showNotices (tournament) {
      this.$store.dispatch('SET_TOURNAMENT', tournament)
      this.$router.push({ name: 'TournamentBackNotices', params: { lang: this.lang, console: this.console, tournamentId: tournament.id } })
    },
    showCustomButtons (tournament) {
      this.$store.dispatch('SET_TOURNAMENT', tournament)
      this.$router.push({ name: 'TournamentBackCustomButtons', params: { lang: this.lang, console: this.console, tournamentId: tournament.id } })
    },
    showRules (tournament) {
      this.$store.dispatch('SET_TOURNAMENT', tournament)
      this.$router.push({ name: 'TournamentBackRules', params: { lang: this.lang, console: this.console, tournamentId: tournament.id } })
    },
    showSignings (tournament) {
      this.$store.dispatch('SET_TOURNAMENT', tournament)
      this.$root.$emit('bv::show::modal', 'modalSignings')
    },
    openModalChangeImage (tournament) {
      this.$store.dispatch('SET_TOURNAMENT', tournament)
      this.hideShowModalChangeImage = true
    },
    openModalAddTeam (tournament) {
      this.$store.dispatch('SET_TOURNAMENT', tournament)
      this.hideShowModalAddTeam = true
    },
    clearFilter () {
      this.filter = ''
      this.filterForUsers = ''
    },
    search () {
      if (this.user.type === 'SUPER') {
        this.fetchData()
      } else {
        this.filterForUsers = this.filter
      }
    },
    fetchData (params = {}) {
      const path = `auth/${this.lang}/${this.console}/tournament/all`
      this.$axios.get(path, { params }).then((response) => {
        const data = response.data.data
        this.perPage = data.per_page
        this.totalRows = data.total
        this.$store.dispatch('SET_TOURNAMENTS', data.data)
      })
    },
    updateData () {
      let params = {}
      if (this.filter.length > 0) {
        params.q = this.filter
        if (this.filter.length === 1) {
          params.page = 1
        }
      } else {
        if (this.currentPage > 1) {
          params.page = this.currentPage
        }
      }
      this.fetchData(params)
    },
    showBanners (tournament) {
      this.$store.dispatch('SET_TOURNAMENT', tournament)
      this.$router.push({ name: 'TournamentBanners', params: { lang: this.lang, console: this.console, tournamentId: tournament.id } })
    },
    showPlayers (tournament) {
      this.$store.dispatch('SET_TOURNAMENT', tournament)
      this.$router.push({ name: 'TournamentPlayers', params: { lang: this.lang, console: this.console, tournamentId: tournament.id } })
    },
    showTeams (tournament) {
      this.$store.dispatch('SET_TOURNAMENT', tournament)
      this.$router.push({ name: 'TournamentBackTeams', params: { lang: this.lang, console: this.console, tournamentId: tournament.id } })
    },
    money (tournament) {
      this.$store.dispatch('SET_TOURNAMENT', tournament)
      this.$root.$emit('bv::show::modal', 'modalMoney')
    }
  }
}
</script>
